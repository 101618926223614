import React from "react"
import Layout from "../components/layout"
import RecipeList from "../components/recipeList"
import CategoryNav from "../components/categoryNav"

export default function Template({ pageContext }) {
  return (
    <Layout>
      <CategoryNav selectedCategorySlug={pageContext.categorySlug} />
      <RecipeList categorySlug={pageContext.categorySlug} />
    </Layout>
  )
}
