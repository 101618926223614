import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components/macro"

const CategoryNav = ({ selectedCategorySlug }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCategory {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `)

  const TagList = styled.div`
    background: #222;
    display: flex;
    padding: 15px 10px 15px 10px;
    width: 100%;
    overflow-x: scroll;
    position: sticky;
    top: 0;
    z-index: 100;
  `

  const TagItem = styled.div`
    text-transform: uppercase;
    display: inline-block;
    padding: 0.4em 0.8em 0.3em;
    border-radius: 5px;
    margin-right: 10px;
    letter-spacing: 0.02em;
    border: 1px solid #777;
    color: #777;
    white-space: nowrap;
    &:hover {
      color: #bbb;
      border-color: #bbb;
    }
  `

  const SelecteTagItem = styled(TagItem)`
    background: #36c;
    border-color: #36c;
    color: #fff;
    &:hover {
      color: #fff;
      border-color: #36c;
    }
  `

  // TODO: Sort by count

  let tags = data.allSanityCategory.nodes.map(tag => {
    return (
      <div key={tag.slug.current}>
        <Link to={`/category/${tag.slug.current}`}>
          {selectedCategorySlug === tag.slug.current ? (
            <SelecteTagItem>{tag.title}</SelecteTagItem>
          ) : (
            <TagItem>{tag.title}</TagItem>
          )}
        </Link>
      </div>
    )
  })
  return (
    <TagList>
      <Link to="/">
        {selectedCategorySlug ? (
          <TagItem>all</TagItem>
        ) : (
          <SelecteTagItem>all</SelecteTagItem>
        )}
      </Link>
      {tags}
    </TagList>
  )
}

export default CategoryNav
