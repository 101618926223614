import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components/macro"

import GatsbyImage from "gatsby-image"

function RecipeList({ categorySlug }) {
  const data = useStaticQuery(graphql`
    query {
      allSanityRecipe {
        nodes {
          title
          description
          slug {
            current
          }
          categories {
            slug {
              current
            }
          }
          photo {
            asset {
              fixed(width: 250, height: 180) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
  `)

  const matches = data.allSanityRecipe.nodes.filter(recipe => {
    const recipeCategories = recipe.categories.map(cat => {
      return cat.slug.current
    })
    if (categorySlug == null) return true
    return recipeCategories.includes(categorySlug)
  })

  const Container = styled.div`
    display: grid;
    grid-gap: 10px;
    padding: 0 10px 10px 10px;
    grid-template-columns: 1fr 1fr;
    max-width: 1400px;
    @media only screen and (min-width: 550px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (min-width: 820px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media only screen and (min-width: 1000px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    @media only screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  `

  const Item = styled.div`
    padding: 10px;
    height: 120px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background: rgb(69, 67, 63);
    background: linear-gradient(
      110deg,
      rgba(83, 80, 75, 1) 0%,
      rgba(159, 157, 151, 1) 100%
    );
  `

  const Title = styled.div`
    color: #fff;
    font-weight: bold;
    position: relative;
    line-height: 1.4;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  `
  const Description = styled(Title)`
    font-size: 14px;
    font-weight: normal;
  `
  const ImageWrapper = styled.div`
    background: #333;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  `

  const ImageFade = styled.div`
    opacity: 0.4;
  `

  const recipes = matches.map(recipe => (
    <Link key={recipe.slug.current} to={`/recipes/${recipe.slug.current}`}>
      <Item>
        {recipe.photo?.asset && (
          <ImageWrapper>
            <ImageFade>
              <GatsbyImage fixed={recipe.photo.asset?.fixed} />
            </ImageFade>
          </ImageWrapper>
        )}
        <Title>{recipe.title}</Title>
        <Description>{recipe.description}</Description>
      </Item>
    </Link>
  ))

  return <Container>{recipes}</Container>
}

RecipeList.defaultProps = {
  tag: null,
}

RecipeList.propTypes = {
  tag: PropTypes.string,
}

export default RecipeList
